@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  custom */
/*  客製化scss */

// Extra small devices & Small devices
.ant-descriptions-item-label {
  width: 20%;
  word-break: break-all;
}

.ant-descriptions-item-content {
  width: 80%;
  word-break: break-all;
}

.note-html {
  line-height: 13px;
}

.print-logo {
  width: 130px;
  float: left;
  margin-right: -130px;
}

.print-table tr.ant-table-expanded-row {
  background: none;
}

// color and bold start

// table
.ant-table {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: bold !important;
}

// descriptions
.ant-descriptions-item-label,
.ant-descriptions-item-content {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: bold !important;
}

// input
.ant-legacy-form-item-label,
.ant-legacy-form-item-children input,
.ant-legacy-form-item-children textarea,
.ant-select-selection-item {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: bold !important;
}

// color and bold end

// print page
.print-col-4 .ant-descriptions-item-label {
  width: 120px;
  word-break: break-all;
  font-size: 16px;
  padding: 2px 4px !important;
}

.print-col-4 .ant-descriptions-item-content {
  width: 15%;
  word-break: break-all;
  font-size: 16px;
  padding: 2px 4px !important;
}

// print page
.print-col-2 .ant-descriptions-item-label {
  width: 120px;
  word-break: break-all;
  font-size: 16px;
  padding: 2px 4px !important;
}

.print-col-2 .ant-descriptions-item-content {
  width: 40%;
  word-break: break-all;
  font-size: 16px;
  padding: 2px 4px !important;
}

@media print {
  .print-form,
  .print-form table {
    color: black;
  }
}

.ql-editor {
  white-space: normal !important;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .ant-descriptions-item-label {
    width: 15%;
    word-break: break-all;
  }

  .ant-descriptions-item-content {
    width: 35%;
    word-break: break-all;
  }
}
